<template>
  <div class="admin-detail">
    <div class="admin-navbar align-left">
      <button class="action-button" @click="$router.go(-1)">{{ $lang.app.back }}</button>
    </div>
    <div class="admin-info">
      <h4 class="info-title">{{ $lang.app.user_info }}</h4>
      <div class="info-fields">
        <div class="info-field">
          <p class="field-label">{{ $lang.app.pin }}:</p>
          <p class="field-value">{{ user.pin }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.fullname }}:</p>
          <p class="field-value">{{ user.fullName }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.phone_number }}:</p>
          <p class="field-value">{{ user.phone }}</p>
        </div>
        <div class="info-field">
          <p class="field-label">{{ $lang.app.email }}:</p>
          <p class="field-value">{{ user.email }}</p>
        </div>
      </div>
      <div class="detail-buttons">
        <button class="detail-button">{{ $lang.app.reset_password }}</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: {
        pin: '12345678901234',
        fullName: 'Бекжигитова Раяна Бексултановна',
        phone: '777555666',
        email: 'rayana@gmail.com'
      },
    }
  },
}
</script>
<style>
@import '../../../assets/styles/admin/detail.css';
@import '../../../assets/styles/admin/info.css';
@import '../../../assets/styles/admin/navbar.css';
</style>